import React from "react";
import { useNavigate } from "react-router-dom";
import "../Thankyou/thankyou.css";



const Policy = () => {

    const navigate = useNavigate();

    return (
        <div className="heads" >

            <div className="homepages"><h1 >Cancellation/Refund policy</h1>
                <br />
                <ul>
                    <li>
                        <h4> No Refund on Cancellation</h4>
                    </li>
                    <li>
                        <h4>Only double payment amount will be refunded</h4>
                    </li>
                </ul>
            </div>
        </div>
    )

}

export default Policy;