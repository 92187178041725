// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDhyZp6xSQeZZBvKnPqkE5mwoUTDIiBjA0",
  authDomain: "userposts-64965.firebaseapp.com",
  projectId: "userposts-64965",
  storageBucket: "userposts-64965.appspot.com",
  messagingSenderId: "483796190733",
  appId: "1:483796190733:web:e29eef974a10885c427744",
  measurementId: "G-N8E9G16ELR"
};



const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);