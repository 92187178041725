import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../Landing Page/landing.css"
// import Mailto from 'react-mailto';
// import { Button } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Swal from "sweetalert2";
// import 'bootstrap/dist/css/bootstrap.min.css';

const Landing = () => {
    const email = "nigfoffice@gmail.com";
    const subject = "Query";




    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const navigate = useNavigate();
    return (
        <div className="header" >
            <div className="image"> <img src="https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/NIGF%2FNIGF%20LOGO.jpeg?alt=media" width="200px"></img>
            </div>

            <div className="description">
                <h1>NORTH INDIA GYNAECOLOGIST FORUM</h1>
                <h3>North India Gynaecologist Forum is a platform to connect Obstetrician& Gynecologist  of 8 states & 2 union territories of North India - Delhi, Uttar Pradesh, Uttarakhand, Punjab, Haryana, Himachal Pradesh, Rajasthan, Jammu, Kashmir & Chandigarh at one platform.
                    <br />
                    <br />
                    Aims of North India Gyneac Forum are unity, excellence & services with key focus on eradication of Anemia, Carcinoma cervix & zero mortality from atonic PPH.
                    <br />
                    <br />
                    Registration fees for joining North India is 1000 INR only for a period of 5 years (2023-2028).
                    For any query in registration contact No.-   +91 6392894791
                    <br />
                    <br />
                </h3>

                <div className="container">
                    <Container>
                        <Row className="container-row">

                            <Col className="container-col" xs={6} sm={6} md={6} lg={3}>
                                <h3>Dr. Sharda jain</h3>
                                <h5>Patron</h5>

                            </Col>
                            <Col className="container-col" xs={6} sm={6} md={6} lg={3}>
                                <h3> Dr. Sadhana Gupta</h3>
                                <h5>President</h5>
                            </Col>
                            <Col className="container-col" xs={6} sm={6} md={6} lg={3}>
                                <h3>Ragini Agrawal</h3>
                                <h5>President-Elect</h5>
                            </Col>
                            <Col className="container-col" xs={6} sm={6} md={6} lg={3}>
                                <h3>Dr. Mala Srivastav</h3>
                                <h5>Secretary General</h5>
                            </Col>
                            <Col className="container-col" xs={6} sm={6} md={6} lg={3}>
                                <h3>Dr. Achla Batra</h3>
                                <h5>Vice President</h5>
                            </Col>
                            <Col className="container-col" xs={6} sm={6} md={6} lg={3}>
                                <h3>Dr. Amrit Pal Kaur</h3>
                                <h5>Vice President</h5>
                            </Col>
                            <Col className="container-col" xs={6} sm={6} md={6} lg={3}>
                                <h3>Dr. Jaya Chaturvedi</h3>
                                <h5>Vice President</h5>
                            </Col>
                        </Row>

                    </Container>
                </div>
            </div>


            <button className="btns" onClick={() => navigate("/registration")}>Register Now</button>

            <div className="query"><a href={`mailto:${email}?subject=${subject}`}>
                Have a Query?
            </a>
            </div>
            <div className="query"><a href="/policy">
                Cancellation/Refund Policy
            </a>
           
            </div>
        </div>
    )
}
export default Landing;
