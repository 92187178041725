import React, { useState, useEffect } from "react";
import "./registration.css"
import axios from "axios"
import { useNavigate } from "react-router-dom";
import { Card, Col, Row } from "react-bootstrap";
import { storage } from "../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"
import Swal from 'sweetalert2';
import { BrowserView } from 'react-device-detect';


const Registration = () => {
    const navigate = useNavigate();

    const [user, setUser] = useState({
        salutation: "",
        FullName: "",
        City: "",
        state: "",
        smcn: "",
        Qualification: "",
        ProfessionalQualification: "",
        AdditionalQualification: "",
        HospitalAffiliation: "",
        number: "",
        email: "",
        address: "",
        profilePic: "",
        hQualification: ""


    })
    const [userId, setUserId] = useState(null)
    // console.log(user);
    const [percent, setPercent] = useState(0);

    // const handleChange = e => {
    //     const { name, value } = e.target
    //     setUser({
    //         ...user,
    //         [name]: value
    //     })
    // }

    const handleChange = e => {
        const { name, value } = e.target;
        // trim email input value. trailing spaces will be trimmed from before and after of any email field only
        const trimmedValue = name === "email" ? value.trim() : value;
        setUser({
          ...user,
          [name]: trimmedValue
        });
      }
      
    const uploadImage = (e, name) => {
        const tempImage = e.target.files[0]

        if (user.email) {
            if (tempImage.type === 'image/jpeg' || tempImage.type === 'image/png' || tempImage.type === 'image/pg') {
                const imageRef = ref(storage, "/NGIF/" + user.email + "-" + name + Date.now() + "." + tempImage.type.split("/")[1]);
                const uploadTask = uploadBytesResumable(imageRef, tempImage);
                Swal.fire({
                    didOpen: () => {
                        Swal.showLoading()
                    },
                    title: 'Uploading...',
                    html: 'Please wait while your image is being uploaded...',
                    allowOutsideClick: false,
                    showConfirmButton: false,

                });

                uploadTask.on(
                    "state_changed",
                    (snapshot) => {
                        const percent = Math.round(
                            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                        );

                        // update progress
                        setPercent(percent);

                        uploadTask.then(function (snapshot) {

                            Swal.fire({
                                icon: 'success',
                                title: `Upload Progress: ${percent}%`,
                                //   text: 'The image has been successfully uploaded to Firebase.'
                            })
                        })

                            .catch(function (error) {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Upload failed :Try Again ',
                                    //   text: 'Upload failed :Try Again '
                                })
                            });




                    },
                    (err) => console.log(err),
                    () => {
                        // download url
                        getDownloadURL(imageRef).then((url) => {
                            // console.log("url--", url)
                            // console.log({
                            //     ...user,
                            //     [name]: url
                            // })
                            setUser({
                                ...user,
                                [name]: url
                            })


                        }).catch(error => {
                            Swal.fire({
                                icon: 'error',
                                title: 'Upload failed :Try Again ',
                                //   text: 'Upload failed :Try Again '
                            })
                        })
                    }
                );
            } else {
                Swal.fire({
                    popup: 'swal2-show',
                    backdrop: 'swal2-backdrop-show',
                    icon: "info",
                    title: "Choose Appropriate File Type",
                })
            }
        } else {
            Swal.fire({
                popup: 'swal2-show',
                backdrop: 'swal2-backdrop-show',
                icon: "info",
                title: "Please Fill Mandatory Fields!",
            })
                .then(() => {
                    let input = document.getElementById("fileInput");
                    input.value = "";
                });



        }

    }

    const handleRegister = async () => {
        try {
            const response = await axios.post(process.env.REACT_APP_ADMIN + "/saveregister", user)
            const savedUserId = response.data.userId.toString()
            // console.log(savedUserId)
            if (savedUserId === "Credit") {
                // alert("user alredy registered!")
                Swal.fire({
                    icon: "error",
                    title: "User already registered!",
                    allowOutsideClick: false,
                }).then(() => {
                    // window.location.reload();
                    window.location.href = window.location.href

                });

                // window.location.reload();
            } else {
                setUserId(savedUserId)
                Swal.fire({
                    backdrop: "transparent",
                    background: "transparent",
                    didOpen: () => {
                        Swal.showLoading()
                    },
                })
                // alert("data saved");
                handlePayment(savedUserId)
            }
            // window.location.reload();

        }
        catch (err) {
            console.log(err);
            Swal.fire({
                popup: 'swal2-show',
                backdrop: 'swal2-backdrop-show',
                icon: "error",
                timer: "10000",
                title: "Something went wrong. please Try Again After Sometime!",

            });
            // window.location.reload();
        };
    }

    const handlePayment = async (tempUserId) => {
        // console.log("-------------------", tempUserId);
        if (tempUserId) {
            const data = {
                purpose: 'Membership_NIGF', // required
                amount: '1000',  // required
                buyer_name: user.FullName,
                email: user.email,
                phone: user.number,
                redirect_url: `https://www.nigf.in/success?user_id=${tempUserId}`,
            };
            // console.log(data);
            axios.post(process.env.REACT_APP_ADMIN + "/createPaymentLink", data)
                .then((res) => {
                    // console.log("resp", res.data);

                    window.location.href = res.data;

                })
                .catch((err) => {
                    // console.log("ftend pay line203 error--")
                    console.log(err.response.data);
                    Swal.fire({
                        popup: 'swal2-show',
                        backdrop: 'swal2-backdrop-show',
                        icon: "info",
                        timer: "10000",
                        title: "Something went wrong. please Check your Mobile Number",

                    });
                })

        } else {
            Swal.fire({
                popup: 'swal2-show',
                backdrop: 'swal2-backdrop-show',
                icon: "error",
                timer: "10000",
                title: "Something went wrong. please Try Again After Sometime!",

            });
        }
    }

//    form validation
    useEffect(() => {
        window.scrollTo(0, 0);
        const form = document.querySelector('form');
        const submitBtn = document.querySelector('button[type="submit"]');

        submitBtn.addEventListener('click', function (event) {
            const inputs = form.querySelectorAll('input[required], select[required]');
            let isValid = true;


            if (!inputs[0].value) {
                inputs[0].style.borderColor = 'red';
                isValid = false;
            } else {
                inputs[0].style.borderColor = '';
            }

            for (let i = 1; i < inputs.length; i++) {
                if (!inputs[i].value) {
                    inputs[i].style.borderColor = 'red';
                    inputs[i].placeholder = 'Field cannot be Empty';
                    inputs[i].classList.add('invalid-input');
                    isValid = false;
                } else {
                    inputs[i].style.borderColor = '';
                    inputs[i].placeholder = '';
                    inputs[i].classList.remove('invalid-input');
                }
            }

            if (!isValid) {
                event.preventDefault();
                // Revert back to the required fields with red border
                const emptyInputs = form.querySelectorAll('input[required]:not(:valid), select[required]:not(:valid)');
                if (emptyInputs.length) {
                    emptyInputs[0].focus();
                }
            }
        });

        // Add event listener to remove red border and text when input is valid
        const requiredInputs = form.querySelectorAll('input[required], select[required]');
        for (let i = 0; i < requiredInputs.length; i++) {
            requiredInputs[i].addEventListener('input', function (event) {
                if (event.target.value) {
                    event.target.style.borderColor = '';
                    event.target.placeholder = '';
                    event.target.classList.remove('invalid-input');
                }
            });
        }

    }, []);


    return (
        <div className="bg">

            {/* <BrowserView>
                <img className="bg-img" src="https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/NIGF%2Fregbg.jpg?alt=media" width="100%" height="100%" />
            </BrowserView> */}
            <div className="head">
                <form onSubmit={(event) => {
                    event.preventDefault()

                    handleRegister()
                }} >
                    <Card style={{ backgroundColor: "transparent", border: "none" }}>
                        <Card.Body>
                            <div className="register">

                                <div className="watermark" > <img src="https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/NIGF%2FNIGF%20LOGO.jpeg?alt=media" width="150px"></img>
                                </div>
                                <h1 className="heading">Register</h1>
                                <p className="pt-3 pb-0 mb-0">Please Fill in the required information below</p>
                                <p className="text-muted py-0">Fields with an (*) are mandatory</p>
                                <label>Full Name <span className="star">*</span> </label>

                                <Row className="registers">
                                    <Col lg={2} md={2} sm={3} xs={3} className="p-0">
                                        <select name="salutation" id="Salutation" className="form-select reg brdr1 text-mute ps-2" value={user.salutation} onChange={handleChange} required>
                                            <option hidden value="">Title</option>
                                            <option value="Dr.">Dr.</option>
                                            <option value="Mr.">Mr.</option>
                                            <option value="Ms.">Ms.</option>
                                            <option value="Mrs.">Mrs.</option>
                                        </select>

                                    </Col>

                                    <Col lg={10} md={10} sm={9} xs={9} className="p-0">
                                        <input type="text" name="FullName" placeholder="Full Name" className="reg brdr2" value={user.FullName} onChange={handleChange} required></input>

                                    </Col>

                                </Row>
                                <label>Mobile Number <span className="star">*</span></label>
                                <input type="text" className="register input" name="number" placeholder="Mobile number" maxLength="10" pattern="\d{10}" value={user.number} onChange={handleChange} required  ></input>

                                <label>Email <span className="star">*</span></label>
                                <input type="text" name="email" className="register input" placeholder="Email Address for correspondence" value={user.email} onChange={handleChange} required ></input>

                                <label>Postal Address for correspondence</label>
                                <input type="text" name="address" className="register input" placeholder="Postal Address for correspondence" value={user.address} onChange={handleChange}></input>

                                <label>City <span className="star">*</span> </label>
                                <input type="text" name="City" className="input register" placeholder="City" value={user.City} onChange={handleChange} required></input>

                                <label>State <span className="star">*</span> </label>
                                <select name="state" id="state" className="form-select  input register" value={user.state} onChange={handleChange} required>
                                    <option hidden value="" >Select State/UT</option>
                                    <option value="Chandigarh">Chandigarh</option>
                                    <option value="Delhi">Delhi</option>
                                    <option value="Haryana">Haryana</option>
                                    <option value="Himachal Pradesh">Himachal Pradesh</option>
                                    <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                                    <option value="Punjab">Punjab</option>
                                    <option value="Uttar Pradesh">Uttar Pradesh</option>
                                    <option value="Uttarakhand">Uttarakhand</option>
                                    <option value="Rajasthan">Rajasthan</option>

                                </select>
                                <label>State Medical Council Number <span className="star">*</span> </label>
                                <input type="text" name="smcn" className="register input" placeholder="Please State Your Registration Number " value={user.smcn} onChange={handleChange} required></input>

                                <label>Highest Qualification</label>
                                <select name="Qualification" id="Qualification" className="form-select register input" value={user.Qualification} onChange={handleChange}>
                                    <option hidden value="">Select Highest Qualification</option>
                                    <option value="UnderGraduate">UnderGraduate</option>
                                    <option value="Post Graduate">Post Graduate</option>
                                    <option value="Formal Education">Formal Education</option>
                                </select>
                                <label>Professional Qualification</label>
                                <input type="text" className="register input" name="ProfessionalQualification" placeholder="Professional Qualification" value={user.ProfessionalQualification} onChange={handleChange}></input>
                                <label>Additional Qualification</label>
                                <input type="text" className="register input" name="AdditionalQualification" placeholder="Additional Qualification/Fellowship" value={user.AdditionalQualification} onChange={handleChange}></input>
                                <label>Hospital Affiliation</label>
                                <input type="text" className="register input" name="HospitalAffiliation" placeholder="Hospital Affiliation/Clinic" value={user.HospitalAffiliation} onChange={handleChange}></input>
                                <label> Upload Highest Qualification  (optional)</label>
                                <span>
                                    <input type="file" className="register input" name="hQualification" id="fileInput" onChange={(e) => { uploadImage(e, "hQualification") }} placeholder="Upload highest Qualification Image" />
                                    {/* <p>{percent} "% done"</p> */}
                                </span>
                                <label> Upload Profile Image (optional)</label>
                                <span>
                                    <input type="file" className="register input" name="profilePic" id="fileInput" onChange={(e) => { uploadImage(e, "profilePic") }} placeholder="Upload Photo" />
                                    {/* <p>{percent} "% done"</p> */}
                                </span>


                                <br></br>
                                <button type="submit" className="btn">Submit</button>
                                <br></br>


                            </div>
                        </Card.Body>
                    </Card>
                </form>


            </div>
        </div>



    );


};

export default Registration;