// import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
// import { useState } from "react";
import Registration from './Registration/registration';
import Landing from './Landing Page/landing';
import Success from './Thankyou/thankyou';
import Policy from './Landing Page/policy';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>

          <Route path="/"
            element={<Landing />}
          ></Route>
           <Route path="/policy"
            element={<Policy />}
          ></Route>


          <Route path="/registration"
            element={<Registration />}
          ></Route>
          <Route path="/success"
            element={<Success />}
          ></Route>


        </Routes>
      </Router>

    </div>
  );
}

export default App;
